var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-4" }, [
      _c(
        "div",
        {
          staticClass: "kt-portlet kt-portlet--height-fluid kt-portlet--mobile",
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("div", { staticClass: "kt-widget kt-widget--user-profile-2" }, [
              _c("div", { staticClass: "kt-widget__head" }, [
                _c("div", { staticClass: "kt-widget__info ml-0 pl-0" }, [
                  _c(
                    "a",
                    {
                      staticClass: "kt-widget__username",
                      attrs: { href: "#" },
                    },
                    [_vm._v(" " + _vm._s(_vm.name) + " ")]
                  ),
                  _c("span", { staticClass: "kt-widget__desc" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ]),
                ]),
              ]),
              _vm._m(1),
              _vm._m(2),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._m(3),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "kt-portlet__head kt-portlet__head--noborder" },
      [
        _c("div", { staticClass: "kt-portlet__head-label" }, [
          _c("h3", { staticClass: "kt-portlet__head-title" }),
        ]),
        _c("div", { staticClass: "kt-portlet__head-toolbar d-none" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-clean btn-sm btn-icon btn-icon-md",
              attrs: { href: "#", "data-toggle": "dropdown" },
            },
            [_c("i", { staticClass: "flaticon-more-1" })]
          ),
          _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
            _c("ul", { staticClass: "kt-nav" }, [
              _c("li", { staticClass: "kt-nav__item" }, [
                _c("a", { staticClass: "kt-nav__link", attrs: { href: "#" } }, [
                  _c("i", {
                    staticClass: "kt-nav__link-icon flaticon2-line-chart",
                  }),
                  _c("span", { staticClass: "kt-nav__link-text" }, [
                    _vm._v("Reports"),
                  ]),
                ]),
              ]),
              _c("li", { staticClass: "kt-nav__item" }, [
                _c("a", { staticClass: "kt-nav__link", attrs: { href: "#" } }, [
                  _c("i", { staticClass: "kt-nav__link-icon flaticon2-send" }),
                  _c("span", { staticClass: "kt-nav__link-text" }, [
                    _vm._v("Messages"),
                  ]),
                ]),
              ]),
              _c("li", { staticClass: "kt-nav__item" }, [
                _c("a", { staticClass: "kt-nav__link", attrs: { href: "#" } }, [
                  _c("i", {
                    staticClass: "kt-nav__link-icon flaticon2-pie-chart-1",
                  }),
                  _c("span", { staticClass: "kt-nav__link-text" }, [
                    _vm._v("Charts"),
                  ]),
                ]),
              ]),
              _c("li", { staticClass: "kt-nav__item" }, [
                _c("a", { staticClass: "kt-nav__link", attrs: { href: "#" } }, [
                  _c("i", {
                    staticClass: "kt-nav__link-icon flaticon2-avatar",
                  }),
                  _c("span", { staticClass: "kt-nav__link-text" }, [
                    _vm._v("Members"),
                  ]),
                ]),
              ]),
              _c("li", { staticClass: "kt-nav__item" }, [
                _c("a", { staticClass: "kt-nav__link", attrs: { href: "#" } }, [
                  _c("i", {
                    staticClass: "kt-nav__link-icon flaticon2-settings",
                  }),
                  _c("span", { staticClass: "kt-nav__link-text" }, [
                    _vm._v("Settings"),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget__body" }, [
      _c("div", { staticClass: "kt-widget__section d-none" }, [
        _vm._v(
          " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical. "
        ),
      ]),
      _c("div", { staticClass: "kt-widget__content d-none" }, [
        _c("div", { staticClass: "kt-widget__stats kt-margin-r-20" }, [
          _c("div", { staticClass: "kt-widget__icon" }, [
            _c("i", { staticClass: "flaticon-piggy-bank" }),
          ]),
          _c("div", { staticClass: "kt-widget__details" }, [
            _c("span", { staticClass: "kt-widget__title" }, [
              _vm._v("Earnings"),
            ]),
            _c("span", { staticClass: "kt-widget__value" }, [
              _c("span", [_vm._v("$")]),
              _vm._v("249,500"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "kt-widget__stats" }, [
          _c("div", { staticClass: "kt-widget__icon" }, [
            _c("i", { staticClass: "flaticon-pie-chart" }),
          ]),
          _c("div", { staticClass: "kt-widget__details" }, [
            _c("span", { staticClass: "kt-widget__title" }, [_vm._v("Net")]),
            _c("span", { staticClass: "kt-widget__value" }, [
              _c("span", [_vm._v("$")]),
              _vm._v("84,060"),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "kt-widget__item pt-5" }, [
        _c("div", { staticClass: "kt-widget__contact" }, [
          _c("span", { staticClass: "kt-widget__label" }, [_vm._v("Contact:")]),
          _c("a", { staticClass: "kt-widget__data", attrs: { href: "#" } }, [
            _vm._v("John Smith"),
          ]),
        ]),
        _c("div", { staticClass: "kt-widget__contact" }, [
          _c("span", { staticClass: "kt-widget__label" }, [_vm._v("Email:")]),
          _c("a", { staticClass: "kt-widget__data", attrs: { href: "#" } }, [
            _vm._v("user@school.com"),
          ]),
        ]),
        _c("div", { staticClass: "kt-widget__contact" }, [
          _c("span", { staticClass: "kt-widget__label" }, [
            _vm._v("Location:"),
          ]),
          _c("span", { staticClass: "kt-widget__data" }, [_vm._v("New York")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget__footer d-none" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-label-success btn-lg btn-upper",
          attrs: { type: "button" },
        },
        [_vm._v(" write message ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-8" }, [
      _c(
        "div",
        {
          staticClass: "kt-portlet kt-portlet--height-fluid kt-portlet--mobile",
        },
        [
          _c("div", { staticClass: "kt-portlet__head" }, [
            _c("div", { staticClass: "kt-portlet__head-label" }, [
              _c("h3", { staticClass: "kt-portlet__head-title" }, [
                _vm._v(" Data Corrections "),
              ]),
            ]),
            _c("div", { staticClass: "kt-portlet__head-toolbar" }),
          ]),
          _c("form", { staticClass: "kt-form" }, [
            _c("div", { staticClass: "kt-portlet__body" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Custom Select")]),
                _c("div"),
                _c("select", { staticClass: "custom-select form-control" }, [
                  _c("option", [_vm._v(" Report a Data Issue ")]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Secure file upload")]),
                _c("div"),
                _c("div", { staticClass: "custom-file" }, [
                  _c("input", {
                    staticClass: "custom-file-input",
                    attrs: { id: "customFile", type: "file" },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "custom-file-label",
                      attrs: { for: "customFile" },
                    },
                    [_vm._v("Choose file")]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "kt-portlet__foot" }, [
              _c("div", { staticClass: "kt-form__actions mb-5" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary pull-right",
                    attrs: { type: "reset" },
                  },
                  [_vm._v(" Submit ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }