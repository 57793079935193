var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("SVGIcon", {
                  staticClass: "kt-svg-icon mt-1 mr-3",
                  attrs: { "hex-color": "#0f88ef", name: "earth" },
                }),
                _c("div", { staticClass: "kt-form__group--inline" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.schoolYear,
                          expression: "schoolYear",
                        },
                      ],
                      staticClass: "form-control kt-font-bolder",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.schoolYear = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.schoolYears, function (y) {
                      return _c(
                        "option",
                        { key: y.value, domProps: { value: y.value } },
                        [_vm._v(" " + _vm._s(y.text) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c("span", { staticClass: "kt-font-bold ml-3" }, [
                  _vm._v(
                    " " + _vm._s(_vm.name) + " " + _vm._s(_vm.title) + " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "left-buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "a",
                  {
                    staticClass: "btn kt-subheader__btn-primary btn-icon",
                    attrs: { href: "#", title: "Show Previous Year" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.nextYear.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("SVGIcon", {
                      attrs: { name: "leftNavigationDoubleArrowLeft" },
                    }),
                  ],
                  1
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn kt-subheader__btn-primary btn-icon ml-3",
                    attrs: { href: "#", title: "Show Next Year" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.previousYear.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("SVGIcon", {
                      attrs: { name: "leftNavigationDoubleArrowRight" },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          key: `key_${_vm.key}`,
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4",
        },
        [
          _c("BiliteracySealAdministration"),
          _c("BiliteracySealStatistics"),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-6" },
              [
                _c("BiliteracySealBigFive", {
                  attrs: { "on-click": _vm.goToBreakdown },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-6" },
              [
                _c("BiliteracySealByEthnicity", {
                  attrs: { "on-click": _vm.goToBreakdown },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-6" },
              [
                _c("BiliteracySealByStatus", {
                  attrs: { "on-click": _vm.goToBreakdown },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-6" },
              [
                _c("BiliteracySealByLanguage", {
                  attrs: { "on-click": _vm.goToBreakdown },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }