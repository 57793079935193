<template>
<div>
    <GeneralSubHeader>
        <template #title>
            <SVGIcon
                hex-color="#0f88ef"
                name="earth"
                class="kt-svg-icon mt-1 mr-3"
            />
            <div class="kt-form__group--inline">
                <select
                    v-model="schoolYear"
                    class="form-control kt-font-bolder"
                >
                    <option
                        v-for="y in schoolYears"
                        :key="y.value"
                        :value="y.value"
                    >
                        {{ y.text }}
                    </option>
                </select>
            </div>
            <span class="kt-font-bold ml-3">
                {{ name }} {{ title }}
            </span>

            <!-- <small class="ml-3">{{ lastUpdated }}</small> -->
        </template>
        <template #left-buttons>
            <!-- what -->
        </template>
        <template #buttons>
            <a
                href="#"
                title="Show Previous Year"
                class="btn kt-subheader__btn-primary btn-icon"
                @click.stop.prevent="nextYear"
            >
                <SVGIcon name="leftNavigationDoubleArrowLeft" />
            </a>
            <a
                href="#"
                title="Show Next Year"
                class="btn kt-subheader__btn-primary btn-icon ml-3"
                @click.stop.prevent="previousYear"
            >
                <SVGIcon name="leftNavigationDoubleArrowRight" />
            </a>
        </template>
    </GeneralSubHeader>

    <div :key="`key_${key}`" class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4">
        <BiliteracySealAdministration />

        <BiliteracySealStatistics />

        <div class="row">
            <div class="col-lg-6">
                <BiliteracySealBigFive :on-click="goToBreakdown" />
            </div>
            <div class="col-lg-6">
                <BiliteracySealByEthnicity :on-click="goToBreakdown" />
            </div>
            <div class="col-lg-6">
                <BiliteracySealByStatus :on-click="goToBreakdown" />
            </div>
            <div class="col-lg-6">
                <BiliteracySealByLanguage :on-click="goToBreakdown" />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import BiliteracySealAdministration from '../components/BiliteracySealAdministration.vue';
import BiliteracySealStatistics from '../components/BiliteracySealStatistics.vue';
import BiliteracySealByLanguage from '../components/BiliteracySealByLanguage.vue';
import BiliteracySealByStatus from '../components/BiliteracySealByStatus.vue';
import BiliteracySealByEthnicity from '../components/BiliteracySealByEthnicity.vue';
import BiliteracySealBigFive from '../components/BiliteracySealBigFive.vue';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import Types from '../store/Types';

export default Vue.extend({
    name: 'BiliteracySealSchoolProfile',
    components: {
        GeneralSubHeader,
        BiliteracySealBigFive,
        BiliteracySealByEthnicity,
        BiliteracySealByStatus,
        BiliteracySealByLanguage,
        BiliteracySealAdministration,
        BiliteracySealStatistics,
    },
    data() {
        return {
            key: 0,
            loading: false,
            show: false,
            showYearPicker: false,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        title() {
            const { fullPath } = this.$route;
            if (fullPath.includes('/school')) {
                return 'School Profile';
            }
            if (fullPath.includes('/district')) {
                return 'District Profile';
            }
            if (fullPath.includes('/region')) {
                return 'Region Profile';
            }
            if (fullPath.includes('/big')) {
                return 'Big 5 Profile';
            }
            return 'unknown';
        },
        name() {
            const id = parseInt(this.$route.params.id, 10);

            const { fullPath } = this.$route;
            if (fullPath.includes('/school')) {
                return this.$store.state.biliteracySeal.schools[id];
            }
            if (fullPath.includes('/district')) {
                return this.$store.state.biliteracySeal.districts[id];
            }
            if (fullPath.includes('/region')) {
                return this.$store.state.biliteracySeal.regions[id];
            }
            if (fullPath.includes('/big')) {
                return this.$store.state.biliteracySeal.big5[id];
            }
            return null;
        },
        schoolYear: {
            set(schoolYear) {
                this.$store.commit(Types.mutations.SET_BILITERACY_SEAL_SCHOOL_YEAR, schoolYear);
            },
            get() {
                return this.$store.state.biliteracySeal.schoolYear;
            },
        },
        schoolYears() {
            return this.$store.state.biliteracySeal.schoolYears;
        },
    },
    watch: {
        name() {
            this.key += 1;
        },
    },
    methods: {
        previousYear() {
            const index = this.schoolYears.findIndex((y) => y.value === this.schoolYear);
            if (index > 0) {
                this.schoolYear = this.schoolYears[index - 1].value;
            }
        },
        nextYear() {
            const index = this.schoolYears.findIndex((y) => y.value === this.schoolYear);
            if (index < this.schoolYears.length - 1) {
                this.schoolYear = this.schoolYears[index + 1].value;
            }
        },
        goToBreakdown(filter) {
            if (!['BigFive', 'ByEthnicity', 'ByStatus', 'ByLanguage'].includes(filter)) return;
            this.$router.push({
                name: 'BiliteracySealBreakdown',
                params: { filter },
            });
        },
    },
});
</script>

<style lang="scss">

</style>
