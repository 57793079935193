<template>
<div class="row">
    <div class="col-4">
        <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title" />
                </div>
                <div class="kt-portlet__head-toolbar d-none">
                    <a
                        href="#"
                        class="btn btn-clean btn-sm btn-icon btn-icon-md"
                        data-toggle="dropdown"
                    >
                        <i class="flaticon-more-1" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <ul class="kt-nav">
                            <li class="kt-nav__item">
                                <a href="#" class="kt-nav__link">
                                    <i class="kt-nav__link-icon flaticon2-line-chart" />
                                    <span class="kt-nav__link-text">Reports</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a href="#" class="kt-nav__link">
                                    <i class="kt-nav__link-icon flaticon2-send" />
                                    <span class="kt-nav__link-text">Messages</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a href="#" class="kt-nav__link">
                                    <i class="kt-nav__link-icon flaticon2-pie-chart-1" />
                                    <span class="kt-nav__link-text">Charts</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a href="#" class="kt-nav__link">
                                    <i class="kt-nav__link-icon flaticon2-avatar" />
                                    <span class="kt-nav__link-text">Members</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a href="#" class="kt-nav__link">
                                    <i class="kt-nav__link-icon flaticon2-settings" />
                                    <span class="kt-nav__link-text">Settings</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="kt-widget kt-widget--user-profile-2">
                    <div class="kt-widget__head">
                        <div class="kt-widget__info ml-0 pl-0">
                            <a href="#" class="kt-widget__username">
                                {{ name }}
                            </a>
                            <span class="kt-widget__desc">
                                {{ title }}
                            </span>
                        </div>
                    </div>
                    <div class="kt-widget__body">
                        <div class="kt-widget__section d-none">
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical.
                        </div>
                        <div class="kt-widget__content d-none">
                            <div class="kt-widget__stats kt-margin-r-20">
                                <div class="kt-widget__icon">
                                    <i class="flaticon-piggy-bank" />
                                </div>
                                <div class="kt-widget__details">
                                    <span class="kt-widget__title">Earnings</span>
                                    <span class="kt-widget__value"><span>$</span>249,500</span>
                                </div>
                            </div>
                            <div class="kt-widget__stats">
                                <div class="kt-widget__icon">
                                    <i class="flaticon-pie-chart" />
                                </div>
                                <div class="kt-widget__details">
                                    <span class="kt-widget__title">Net</span>
                                    <span class="kt-widget__value"><span>$</span>84,060</span>
                                </div>
                            </div>
                        </div>
                        <div class="kt-widget__item pt-5">
                            <div class="kt-widget__contact">
                                <span class="kt-widget__label">Contact:</span>
                                <a href="#" class="kt-widget__data">John Smith</a>
                            </div>
                            <div class="kt-widget__contact">
                                <span class="kt-widget__label">Email:</span>
                                <a href="#" class="kt-widget__data">user@school.com</a>
                            </div>
                            <div class="kt-widget__contact">
                                <span class="kt-widget__label">Location:</span>
                                <span class="kt-widget__data">New York</span>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__footer d-none">
                        <button type="button" class="btn btn-label-success btn-lg btn-upper">
                            write message
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-8">
        <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Data Corrections
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <!-- nope -->
                </div>
            </div>

            <form class="kt-form">
                <div class="kt-portlet__body">
                    <div class="form-group">
                        <label>Custom Select</label>
                        <div />
                        <select class="custom-select form-control">
                            <option>
                                Report a Data Issue
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Secure file upload</label>
                        <div />
                        <div class="custom-file">
                            <input
                                id="customFile"
                                type="file"
                                class="custom-file-input"
                            >
                            <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__foot">
                    <div class="kt-form__actions mb-5">
                        <button type="reset" class="btn btn-primary pull-right">
                            Submit
                        </button>
                    </div>
                </div>
            </form>

            <!--end::Form-->
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';

export default Vue.extend({
    name: 'BiliteracySealAdministration',
    components: {},
    mixins: [],
    data() { return {}; },
    computed: {
        title() {
            const { fullPath } = this.$route;
            if (fullPath.includes('/school')) {
                return 'School Profile';
            }
            if (fullPath.includes('/district')) {
                return 'District Profile';
            }
            if (fullPath.includes('/region')) {
                return 'Region Profile';
            }
            if (fullPath.includes('/big')) {
                return 'Big 5 Profile';
            }
            return 'unknown';
        },
        name() {
            const id = parseInt(this.$route.params.id, 10);

            const { fullPath } = this.$route;
            if (fullPath.includes('/school')) {
                return this.$store.state.biliteracySeal.schools[id];
            }
            if (fullPath.includes('/district')) {
                return this.$store.state.biliteracySeal.districts[id];
            }
            if (fullPath.includes('/region')) {
                return this.$store.state.biliteracySeal.regions[id];
            }
            if (fullPath.includes('/big')) {
                return this.$store.state.biliteracySeal.big5[id];
            }
            return null;
        },

    },
    mounted() {

    },
    methods: { },
});

</script>

<style lang="scss">

</style>
